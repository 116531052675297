<template>
  <div class="slider-carousel">
    <!--  v-if="!contents" -->
    <div v-if="!contents" class="placeholder">
      <Placeholder :orientation="orientation" :gridStyle="getGridStyle" :numOfTimes="noOfDisplay" />
    </div>
    <div class="greater-main-div mobileview" v-if="contents.length && mobileview">
       <div v-for="(content, i) in contents" :key="i">
        <SliderCarousel
          style="width:100%;"
          :content="content"
          :orientation="orientation"
          :showContentDetailEvent="() => showDetail(content)"
          :activeTabName="activeTabName"
          :trailerPoster="activeTabName === 'Trailers' ? trailerPoster : ''"
          @click="activeTabName === 'Related'? actRelated:''"
          :routeCard="routeCard"
          :closeDetailCard="closeDetailCard"
          :isDisplayWatch="isDisplayWatch"
          :parentContent="cardContent"
        />
      </div>
    </div>
    <div class="greater-main-div" v-else-if="contents.length && contents.length > 2 && !mobileview">
      <div class="slide-head-btns">
        <div
          class="slide-controls controls"
          :id="`slider-detail-card-child-controls`"
        >
          <div class="btn-slide-controls prev" @click="actLeftArrow" aria-label="left button">
            <img :class="{'hide-arrow':!sliderArrowLeft}" src="@/assets/icons/ico-prev.svg" alt="prev" loading="lazy" />
          </div>
          <div class="btn-slide-controls next" @click="actRightArrow" aria-label="left button">
            <img :class="{'hide-arrow':!sliderArrowRight}" src="@/assets/icons/ico-next.svg" alt="next" loading="lazy" />
          </div>
        </div>
      </div>
      <div class="car-slider" :id="`car-slider-detail-card-child`" ref="sliderparent">
        <div v-for="(content, i) in contents" :key="i" class="car-slide" >
          <SliderCarousel
            :class="orientation === 'LANDSCAPE' ? 'landscape-div': 'potrait-div'"
            :content="content"
            :orientation="orientation"
            :activeTabName="activeTabName"
            :showContentDetailEvent="() => showDetail(content)"
            :trailerPoster="activeTabName === 'Trailers' ? trailerPoster : ''"
            @click="activeTabName === 'Related'? actRelated:''"
            :routeCard="routeCard"
            :closeDetailCard="closeDetailCard"
            :isDisplayWatch="isDisplayWatch"
            :parentContent="cardContent"
          />
        </div>
      </div>
    </div>
    <div class="less-main-div" :style="getGridStyle" v-else-if="contents.length>0">
      <div v-for="(content, i) in contents" :key="i">
        <SliderCarousel
          :content="content"
          :orientation="orientation"
          :showContentDetailEvent="() => showDetail(content)"
          :activeTabName="activeTabName"
          :trailerPoster="activeTabName === 'Trailers' ? trailerPoster : ''"
          @click="activeTabName === 'Related'? actRelated:''"
          :routeCard="routeCard"
          :closeDetailCard="closeDetailCard"
          :isDisplayWatch="isDisplayWatch"
          :parentContent="cardContent"
        />
      </div>
    </div>
    <div v-else>
      <p>{{errorMessage}}</p>
    </div>
  </div>
</template>

<script>
import appMixins from "@/mixins/appMixins";
import { mapGetters, mapMutations, mapActions } from "vuex";
import { EventBus } from "@/main";
import { tns } from "tiny-slider/src/tiny-slider.module";

let responsiveData={
            1200: {
              edgePadding: 0,
              gutter: 20,
              items: 3
            },
            1024: {
              edgePadding: 0,
              gutter: 10,
              items: 3
            },
            768: {
              edgePadding: 0,
              gutter: 15,
              items: 3
            },
            576: {
              edgePadding:0,
              gutter: 5,
              items: 2
            }
          };

export default {
  props: {
    activeTabName: {
      type: String
    },
    cardContent: {
      type: Object
    },
    isDisplayWatch: {
      type: Boolean
    },
    routeCard:{
      type:Function
    },
    closeDetailCard:{
      type:Function
    }
  },
  data() {
    return {
      contents: "",
      detailContent: true,
      orientation: "LANDSCAPE",
      slider: "",
      trailerPoster: "",
      errorMessage: "",
      noOfDisplay: 1,
      placeholderGap:0,
      page: 0,
      contentCount: 0,
      mobileview:false,
      sliderArrowLeft:false,
      sliderArrowRight:true

      // errorDisplay: false
    };
  },
  watch: {
    cardContent() {
      this.page = 0;
      if (this.activeTabName == "Trailers") {
        this.getTrailerContentDetails();
      } else {
        this.getContentDetails();
      }
    },
    activeTabName() {
      this.page = 0;
      this.sliderArrowLeft=false;
      this.sliderArrowRight=true;
      if (this.activeTabName == "Trailers") {
        this.getTrailerContentDetails();
      } else {
        this.getContentDetails();
      }
      this.getPlaceholderCount(window.innerWidth);
    },

  },
  methods: {
    ...mapActions(["actListContents", "actListTrailerContents"]),
    actRelated() {
      let obj = {};
      obj.content = this.cardContent;
      EventBus.$emit("relatedContentClicked", obj);
    },
    getContentDetails() {
      this.page++;
      this.contentCount += 15;

      if (this.page === 1) {
        this.contents = "";
      }
      let data = {};

      data.page = this.page;
      if (this.activeTabName === "Related") {
        data.contenttype = this.cardContent.contenttype;
        data.category = this.cardContent.category;
      } else if (this.activeTabName === "Episodes") {
        if (this.cardContent.seriesid) {
          data.seriesid = this.cardContent.seriesid;
        } else if (this.cardContent.contentid) {
          data.seriesid = this.cardContent.contentid;
        }
      }
      data.streamcodec = "DASH";
      if (
        this.activeTabName === "Related" &&
        this.cardContent.contenttype === "MOVIE"
      ) {
        this.orientation = "POTRAIT";
      } else {
        this.orientation = "LANDSCAPE";
      }

      this.actListContents(data)
        .then(response => {
          if(response && response.error){
           return this.sliderArrowRight=false;            
          }
          if (response && response.length) {
            if (this.page === 1) {
              this.contents = [];
            }
            // this.contents = [];
            // if (this.contents.length > 1) {

            //   this.contents = [...this.contents, ...response];
            // } else {
            //   this.contents = response;
            // }

            response.forEach(element => {
              if (element.contentid !== this.cardContent.contentid) {
                // should not display the content on which it is clicked
                this.contents.push(element);
              }
            });
            // this.contents = [...response, ...response, ...response];
            if (this.contents.length === 0) {
              if (this.activeTabName === "Related") {
                this.errorMessage = "No related content";
              } else {
                this.errorMessage = "No episode content";
              }
            }

            if (!this.contents.length) return;
            if (this.contents.length && this.contents.length > 2) {
              if (this.page === 1) {
                this.sliderMovement();
              } else {
                this.sliderMovement(counterIndex);
              }
            }
          }
        })
        .catch(error => {
          console.log(error);
          this.sliderArrowRight=false; 
          // if (error.status === 401) {
          //   this.unauthorisedHandler();
          // }
        });
    },

    getTrailerContentDetails() {
      this.contents = "";
      this.orientation = "LANDSCAPE";
      let data = {};
      data.contentid = this.cardContent.contentid;
      data.streamcodec = "DASH";
      this.actListTrailerContents(data)
        .then(response => {
          if (response.trailer && response.trailer.length) {
            this.contents = response.trailer;

            if (response.posters.length) {
              let landscapeposters = response.posters.filter(
                pos => pos.orientation === "LANDSCAPE"
              );

              if (landscapeposters.length) {
                let posterIndex = landscapeposters.findIndex(
                  pos => pos.quality === "SD"
                );
                if (posterIndex > -1) {
                  this.trailerPoster = landscapeposters[posterIndex].url;
                } else {
                  this.trailerPoster = landscapeposters[0].url;
                }
              }
            }

            if (this.contents.length && this.contents.length > 2) {
              this.sliderMovement();
            }
          } else {
            this.contents = [];
            this.errorMessage = "No trailers contents";
          }
        })
        .catch(error => {
          console.log(error);
          // if (error.status === 401) {
          //   this.unauthorisedHandler();
          // }
        });
    },
    sliderMovement(flag = false) {
      this.$nextTick(() => {
        if(!document.querySelector("#car-slider-detail-card-child"))return false;
        if(this.slider && this.slider.isOn){
          this.slider.destroy();
          document.querySelectorAll("#car-slider-detail-card-child").forEach(el=>{
            if(el !==this.$refs.sliderparent){
              el.remove();  //slider destroy method bug fix.
            }
          });
        };
        this.slider = tns({
          container: `#car-slider-detail-card-child`,
          controlsContainer: `#slider-detail-card-child-controls`,
          nav: false,
          loop: false,
          slideBy: 1,
          gutter: 0,
          edgepadding: 0,
          responsive: responsiveData
        });
        flag ? this.slider.goTo(flag) : "";
        console.log(this.slider);
      });
    },
    actRightArrow() {
      this.sliderArrowLeft = true;
      var info = this.slider.getInfo();
      if ((info.displayIndex + info.items) >= info.slideCount) {
        if (this.activeTabName !== "Trailers"){
          this.getContentDetails(info.displayIndex);
        }
        else this.sliderArrowRight=false;
      }
    },
    actLeftArrow() {
      this.sliderArrowRight = true;
      var info = this.slider.getInfo()
      if(info && info.index === 1){
        this.sliderArrowLeft = false
      }
    },
    setView(){
      if (window.innerWidth<576){
        this.mobileview=true;
        if(this.slider && this.slider.isOn){
          this.slider.destroy();
          document.querySelectorAll("#car-slider-detail-card-child").forEach(el=>{
              if(el !==this.$refs.sliderparent){
                el.remove();  //slider destroy method bug fix.
              }
          });
        }
      }
      else {
        this.mobileview=false;
        if(!(this.slider && this.slider.isOn)){
          this.sliderMovement();
        }
      }
    },
    getPlaceholderCount(width){
        let resArr=Object.entries(responsiveData).reverse().find(([breakpoint,data])=>{
          if(width>=Number(breakpoint)){
            return true;
          }
          else {
            return false;
          }
        });

        if(resArr){
          this.noOfDisplay=resArr[1].items;
          this.placeholderGap=resArr[1].gutter;
        }
    }

  },
  mounted() {
    this.setView();
    window.addEventListener('resize',this.setView);
  },
  beforeUnmount(){
    if(this.slider && this.slider.isOn){
      this.slider.destroy();
    }
  },
  beforeDestroy(){
    window.removeEventListener('resize',this.setView);
  },
  computed: {
    getGridStyle(){
      let gridTemplateColumns=`repeat(${this.noOfDisplay}, minmax(0, 1fr))`;
      let columnGap=`${this.placeholderGap}px`;
      return {columnGap,gridTemplateColumns};  
    }
  },
  created() {
    this.getPlaceholderCount(window.innerWidth);
    if (this.activeTabName == "Trailers") {
      this.getTrailerContentDetails();
    } else {
      this.getContentDetails();
    }
  },
  components: {
    SliderCarousel: () =>
      import(
        /* webpackChunkName: "sliderCarousal" */ "@/components/Shared/templates/contentDetailCardChildSlider.vue"
      ),
    Placeholder: () =>
      import(
        /* webpackChunkName: "placeHolder" */ "@/components/Shared/templates/placeholder.vue"
      )
  },
  mixins: [appMixins]
};
</script>

<style lang="scss" scoped>
@import "~sass/modules/_variables.scss";
@import "~sass/modules/_fonts.scss";
@import "node_modules/tiny-slider/src/tiny-slider.scss";

p {
  font-family: $font-family;
  font-weight: $font-weight-medium;
}
img[alt="prev"],
img[alt="next"] {
  cursor: pointer;
  transition: all 0.3s;
  &:hover {
    color: white;
    transform: scale(1.1);
    .a {
      fill: white !important;
    }
  }
  &:active{
    color: white;
    transform: scale(0.9);
    .a {
      fill: white !important;
    }
  }
}

.greater-main-div {
 width:100%;
}
.less-main-div {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  grid-gap: 40px;
}
.card-detail {
  margin: 0 0px;
  border-radius: 5px;
}
.card-detail-box {
  position: relative;
}
.card-detail-box-contain {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
}
.car-slider {
  display: flex;
}

// .slider-carousel {
.tns-inner {
  margin: 0px !important;
}
// }
.slide-head-btns{
  position: absolute;
  top:0;
  right:0;
  width:clamp(35px,3vw,20%);
}
.btn-slide-controls img{
  width:12px;
  &.hide-arrow{
    opacity:0.3;
  }
}
.controls {
  margin: 0;
  padding: 0; 
  height: max-content;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

</style>